//src/i18n/locales/en.js
export default {
  // Navigation
  "nav.home": "Home",
  "nav.services": "Services",
  "nav.mission": "Mission",
  "nav.about_us": "About Us",
  "nav.contact": "Contact",

  // Hero Section
  "hero.title": "Your fast and safe ride",
  "hero.title.your": "Your",
  "hero.title.fast": "fast",
  "hero.title.and": "and",
  "hero.title.safe": "safe",
  "hero.title.ride": "ride",
  "hero.subtitle": "Just one click away with Martinto Gomez Taxi",
  // Abaut Us
  "about_us.title": "About Us",
  "abaut_us.subtitle": "Our Fleet",
  "abaut_us.text":
    "At Martinto Gomez Taxi, we offer reliable and personalized transportation with dedicated drivers. Our goal is to provide a comfortable and stress-free experience, ensuring you reach your destination safely and on time.",
  // Hyundai-i30
  "Hyundai-i30.name": "Hyundai i30",
  "Hyundai i30.users": "3-4 people",
  "Hyundai-i30.features.capacity": "Capacity: 4 passengers",
  "Hyundai-i30.features.efficient": "Efficient consumption",
  "Hyundai-i30.features.air": "Air conditioning",
  "Hyundai-i30.features.load_capacity": "Spacious trunk",
  "Hyundai-i30.features.wifi": "Onboard Wi-Fi",
  "Hyundai-i30.features.music": "Premium audio system",

  // Mercedes V Class
  "Mercedes V Class.name": "Mercedes V Class",
  "Mercedes V Class.users": "7 people",
  "Mercedes V Class.features.capacity": "Capacity: 7 passengers",
  "Mercedes V Class.features.confort": "Maximum comfort",
  "Mercedes V Class.features.air": "Tri-zone climate control",
  "Mercedes V Class.features.entretainment": "Onboard entertainment",
  "Mercedes V Class.features.wifi": "Onboard Wi-Fi",
  "Mercedes V Class.features.battery": "USB ports in every row",

  // Alfa Romeo 4C
  "Alfa Romeo 4C.name": "Alfa Romeo 4C",
  "Alfa Romeo 4C.users": "3-4 people",
  "Alfa Romeo 4C.features.capacity": "Capacity: 3 passengers",
  "Alfa Romeo 4C.features.efficient": "Sports performance",
  "Alfa Romeo 4C.features.safety": "Maximum safety",
  "Alfa Romeo 4C.features.air": "Dual-zone climate control",
  "Alfa Romeo 4C.features.navigation": "Premium navigation",
  "Alfa Romeo 4C.features.music": "Audio Harman Kardon",

  // Services Section
  "services.title": "Our Services",
  "services.24_7": "24/7 Availability",
  "services.24_7_desc":
    "Reliable transportation anytime, day or night, whenever you need it.",
  "services.driver": "Experienced Driver",
  "services.driver_desc":
    "Professional driving with years of experience for a smooth and safe journey.",
  "services.airport": "Airport",
  "services.airport_desc":
    "Seamless airport transfers with punctuality and comfort guaranteed.",
  "services.events": "Events",
  "services.events_desc":
    "Trustworthy rides for any event, whether it's a wedding, birthday, or corporate party.",
  "services.hospitals": "Hospitals",
  "services.hospitals_desc":
    "Reliable transportation to and from hospitals and clinics, ensuring timely arrivals.",
  "services.nursing_homes": "Nursing Homes",
  "services.nursing_homes_desc":
    "Comfortable rides for seniors to and from nursing homes, always with a helping hand.",
  "services.travel_tours": "Travel/Tours",
  "services.travel_tours_desc":
    "Experience the best of your city with our guided tours or comfortable rides to and from the airport.",
  "services.business_vip_transfer": "Business VIP Transfer",
  "services.business_vip_transfer_desc":
    "Professional, punctual and personalized transportation for your company's most important clients.",
  "services.economic_transfer": "Economic Transfer",
  "services.economic_transfer_desc":
    "Affordable and reliable rides for any occasion, without compromising on quality.",
  "services.premium_transfer": "Premium Transfer",
  "services.premium_transfer_desc":
    "Top-notch rides for special occasions, complete with luxurious vehicles and impeccable service.",

  // Mission Section
  "mission.title": "Mission",
  "mission.text":
    "To provide personalized, safe, and reliable transportation services with a focus on convenience and customer satisfaction, ensuring every ride feels like a premium experience.",
  "vision.title": "Vision",
  "vision.text":
    "To become the most trusted and preferred solo driver service in the city, known for exceptional customer care, efficiency, and a commitment to safety.",

  // About Section
  "about.title": "Our Fleet",
  "about.description":
    "At Martinto Gomez Taxi, we offer reliable and personalized transportation with dedicated drivers. Our goal is to provide a comfortable and stress-free experience, ensuring you reach your destination safely and on time.",

  // Contact Section
  "contact.title": "Contact Us",
  "contact.name_placeholder": "Name",
  "contact.email_placeholder": "Email",
  "contact.message_placeholder":"Message",
  "contact.send": "Send",
  "contact.whatsapp": "WhatsApp",
  "contact.instagram": "Instagram",
  "contact.facebook": "Facebook",
  "contact.linkedin": "LinkedIn",
  "contact.phone": "Phone",
  "contact.message": "We are here to help you. Contact us through the following means:",
};
