import React from 'react';
import { Navigation } from './Navigation';
import LanguageSelector from '../ui/LanguageSelector';
import logo from '../../assets/images/logo.png';
export const Header = () => {
  return (
    <header className="fixed w-full top-0 z-50 bg-black/80 backdrop-blur-sm">
      <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <img src= {logo} alt="MG TAXI" className="h-12 mr-2" />
         
        </div>
        <Navigation />
        <LanguageSelector />
      </div>
    </header>
  );
};