import React from 'react';
import { Phone, MessageSquare, Instagram, Facebook, ChevronDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
export const Hero = () => {
  const { t } = useTranslation();
  const message = encodeURIComponent("Hola, estoy interesado en su servicio de taxi.");
  const socialLinks = {
    whatsapp: `https://wa.me/+41755777700?text=${message}`,
    instagram: "https://instagram.com/martintogomez.taxi",
    facebook: "https://www.facebook.com/profile.php?id=61568516343141"
  };

  const handleSocialClick = (platform) => {
    window.open(socialLinks[platform], '_blank');
  };

  return (
    <section id="inicio" className="relative h-screen bg-black text-white">
      {/* Contenedor principal con padding top para el navbar */}
      <div className="absolute inset-0 bg-[url('/public/taxi-amarillo-conduciendo-calle-edificios-al-fondo_725455-658.png')] opacity-50 bg-cover bg-center" />
      
      {/* Contenedor con altura máxima y scroll si es necesario */}
      <div className="relative container mx-auto px-4 h-full flex items-center">
        <div className="w-full flex flex-col items-center justify-center gap-8 py-16">
          {/* Título y texto con tamaño reducido */}
          <h1 className="text-3xl md:text-5xl font-bold tracking-wider space-y-1">
            <div>{t('hero.title.your')}</div>
            <div>{t('hero.title.fast')}</div>
            <div>{t('hero.title.and')}</div>
            <div>{t('hero.title.safe')}</div>
            <div>{t('hero.title.ride')}</div>
          </h1>
          
          <p className="text-lg md:text-xl">{t('hero.subtitle')}</p>
          
          {/* Contenedor de botones con altura máxima y scroll si es necesario */}
          <div className="flex flex-col gap-3 w-full max-w-sm">
            {/* WhatsApp */}
            <button 
              onClick={() => handleSocialClick('whatsapp')}
              className="flex items-center justify-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors duration-300"
            >
              <Phone size={18} />
              WhatsApp
            </button>

            {/* Instagram */}
            <button 
              onClick={() => handleSocialClick('instagram')}
              className="flex items-center justify-center gap-2 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white px-4 py-2 rounded-lg hover:opacity-90 transition-opacity duration-300"
            >
              <Instagram size={18} />
              Instagram
            </button>

            {/* Facebook */}
            <button 
              onClick={() => handleSocialClick('facebook')}
              className="flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
            >
              <Facebook size={18} />
              Facebook
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;