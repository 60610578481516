//src/i18n/locales/de.js
export default {
  // Navigation
  "nav.home": "Startseite",
  "nav.services": "Dienstleistungen",
  "nav.mission": "Mission",
  "nav.about_us": "Über uns",
  "nav.contact": "Kontakt",

  // Hero Section
  "hero.title": "Ihre schnelle und sichere Fahrt",
  "hero.title.your": "Ihre",
  "hero.title.fast": "schnelle",
  "hero.title.and": "und",
  "hero.title.safe": "sichere",
  "hero.title.ride": "Fahrt",
  "hero.subtitle": "Nur einen Klick entfernt mit Martinto Gomez Taxi",

  // About Us Section
  "about_us.title": "Über uns",
  "about_us.subtitle": "Unsere Flotte",
  "abaut_us.text":
    "Bei Martinto Gomez Taxi bieten wir zuverlässigen und personalisierten Transport mit engagierten Fahrern an. Unser Ziel ist es, Ihnen eine komfortable und stressfreie Erfahrung zu bieten und sicherzustellen, dass Sie sicher und pünktlich an Ihr Ziel gelangen.",
  // Hyundai-i30
  "Hyundai-i30.name": "Hyundai i30",
  "Hyundai i30.users": "3-4 personen",
  "Hyundai-i30.features.capacity": "Kapazität: 4 Passagiere",
  "Hyundai-i30.features.efficient": "Effizienter Verbrauch",
  "Hyundai-i30.features.air": "Klimaanlage",
  "Hyundai-i30.features.load_capacity": "Geräumiger Kofferraum",
  "Hyundai-i30.features.wifi": "WLAN an Bord",
  "Hyundai-i30.features.music": "Premium-Audiosystem",

  // Mercedes V Class
  "Mercedes V Class.name": "Mercedes V Class",
  "Mercedes V Class.users": "7 personen",
  "Mercedes V Class.features.capacity": "Kapazität: 7 Passagiere",
  "Mercedes V Class.features.confort": "Maximum comfort",
  "Mercedes V Class.features.air": "Drei-Zonen-Klimaanlage",
  "Mercedes V Class.features.load_capacity": "Geräumiger Kofferraum",
  "Mercedes V Class.features.entretainment": "Unterhaltung an Bord",
  "Mercedes V Class.features.wifi": "WLAN an Bord",
  "Mercedes V Class.features.battery": "USB-Anschlüsse in jeder Reihe",

  // Alfa Romeo 4C
  "Alfa Romeo 4C.name": "Alfa Romeo 4C",
  "Alfa Romeo 4C.users": "3-4 personen",
  "Alfa Romeo 4C.features.capacity": "Kapazität: 3 Passagiere",
  "Alfa Romeo 4C.features.efficient": "Sportliche Leistung",
  "Alfa Romeo 4C.features.safety": "Höchste Sicherheit",
  "Alfa Romeo 4C.features.navigation": "Premium-Navigation",
  "Alfa Romeo 4C.features.air": "Zwei-Zonen-Klimaanlage",
  "Alfa Romeo 4C.features.music": "Audio Harman Kardon",

  // Services Section
  "services.title": "Unsere Dienstleistungen",
  "services.24_7": "24/7 Verfügbarkeit",
  "services.24_7_desc":
    "Zuverlässiger Transport zu jeder Zeit, Tag und Nacht, wann immer Sie ihn brauchen.",
  "services.driver": "Erfahrener Fahrer",
  "services.driver_desc":
    "Professionelles Fahren mit jahrelanger Erfahrung für eine reibungslose und sichere Fahrt.",
  "services.airport": "Flughafen",
  "services.airport_desc":
    "Nahtlose Flughafentransfers mit garantierter Pünktlichkeit und Komfort.",
  "services.events": "Veranstaltungen",
  "services.events_desc":
    "Zuverlässige Fahrten für jede Veranstaltung, sei es eine Hochzeit, ein Geburtstag oder eine Firmenfeier.",
  "services.hospitals": "Krankenhäuser",
  "services.hospitals_desc":
    "Zuverlässiger Transport zu und von Krankenhäusern und Kliniken, der rechtzeitige Ankünfte sicherstellt.",
  "services.nursing_homes": "Pflegeheime",
  "services.nursing_homes_desc":
    "Bequeme Fahrten für Senioren zu und von Pflegeheimen, immer mit einer helfenden Hand.",
  "services.travel_tours": "Reisen/Touren",
  "services.travel_tours_desc":
    "Erleben Sie das Beste Ihrer Stadt mit unseren geführten Touren oder komfortablen Fahrten zu und vom Flughafen.",
  "services.business_vip_transfer": "Business VIP Transfer",
  "services.business_vip_transfer_desc":
    "Professioneller, pünktlicher und personalisierter Transport für die wichtigsten Kunden Ihres Unternehmens.",
  "services.economic_transfer": "Wirtschaftlicher Transfer",
  "services.economic_transfer_desc":
    "Erschwingliche und zuverlässige Fahrten für jeden Anlass, ohne Kompromisse bei der Qualität.",
  "services.premium_transfer": "Premium Transfer",
  "services.premium_transfer_desc":
    "Erstklassige Fahrten für besondere Anlässe, komplett mit luxuriösen Fahrzeugen und tadellosem Service.",

  // Mission Section
  "mission.title": "Mission",
  "mission.text":
    "Personalisierte, sichere und zuverlässige Transportdienste mit Fokus auf Komfort und Kundenzufriedenheit anzubieten und dabei sicherzustellen, dass sich jede Fahrt wie ein Premium-Erlebnis anfühlt.",
  "vision.title": "Vision",
  "vision.text":
    "Der vertrauenswürdigste und bevorzugte Einzelfahrerdienst in der Stadt zu werden, bekannt für außergewöhnlichen Kundenservice, Effizienz und Engagement für Sicherheit.",

  // About Section
  "about.title": "Unsere Flotte",
  "about.description":
    "Bei Martinto Gomez Taxi bieten wir zuverlässige und personalisierte Beförderung mit engagierten Fahrern. Unser Ziel ist es, ein komfortables und stressfreies Erlebnis zu bieten und sicherzustellen, dass Sie sicher und pünktlich an Ihrem Ziel ankommen.",

  // Contact Section
  "contact.title": "Kontaktieren Sie uns",
  "contact.name_placeholder": "Name",
  "contact.email_placeholder": "E-Mail",
  "contact.message_placeholder": "Nachricht",
  "contact.send": "Senden",
  "contact.whatsapp": "WhatsApp",
  "contact.instagram": "Instagram",
  "contact.facebook": "Facebook",
  "contact.linkedin": "LinkedIn",
  "contact.phone": "Telefon",
  "contact.message":
    "Wir sind hier, um Ihnen zu helfen. Kontaktieren Sie uns durch die folgenden Mittel:",
};
