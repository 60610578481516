// components/About/About.jsx
import React from 'react';
import { VehicleCarousel } from './About/VehicleCarousel';
// import { vehicles } from '../../data/vehicles';
import { useTranslation } from 'react-i18next';

import hyundai_1 from "../../assets/images/hyundai/auto_fondo_vidriera.jpg";
import hyundai_2 from "../../assets/images/hyundai/hyundai_2.jpeg";
import hyundai_3 from "../../assets/images/hyundai/hyundai_3.jpeg";
import mercedes_1 from "../../assets/images/mercedes/mercedez_1.jpeg";
import mercedes_2 from "../../assets/images/mercedes/mercedez-2.jpeg";
import mercedes_3 from "../../assets/images/mercedes/mercedez-3.jpeg";
import alfa_1 from "../../assets/images/alfa/alfa_1.jpeg";
import alfa_2 from "../../assets/images/alfa/alfa_2.jpeg";
import alfa_3 from "../../assets/images/alfa/alfa_3.jpeg";

export const About = () => {
  const { t } = useTranslation();
  const vehicles = [
    {
      id: 1,
      name: "Hyundai i30",
      capacity: t("Hyundai i30.users"),
      features: [
        { icon: "Users", text: t("Hyundai i30.capacity") },
        { icon: "Fuel", text: t("Hyundai-i30.features.efficient") },
        { icon: "Wind", text: t("Hyundai-i30.features.air") },
        { icon: "Luggage", text: t("Hyundai-i30.features.load_capacity") },
        { icon: "Wifi", text: t("Hyundai-i30.features.wifi") },
        { icon: "Music", text: t("Hyundai-i30.features.music") }
      ],
      images: [
        hyundai_1,
        hyundai_2,
        hyundai_3
      ]
    },
    {
      id: 2,
      name: "Mercedes V Class",
      capacity: t("Mercedes V Class.users"),
      features: [
        { icon: "Users", text: t("Mercedes V Class.features.capacity") },
        { icon: "ThumbsUp", text: t("Mercedes V Class.features.confort") },
        { icon: "Wind", text: t("Mercedes V Class.features.air") },
        { icon: "Tv", text: t("Mercedes V Class.features.entretainment") },
        { icon: "Wifi", text: t("Mercedes V Class.features.wifi") },
        { icon: "BatteryFull", text: t("Mercedes V Class.features.battery") }
      ],
      images: [
        mercedes_1,
        mercedes_2,
        mercedes_3
      ]
    },
    {
      id: 3,
      name: "Alfa Romeo Stelvio",
      capacity: t("Alfa Romeo 4C.users"),
      features: [
        { icon: "Users", text: t("Alfa Romeo 4C.features.capacity") },
        { icon: "Gauge", text: t("Alfa Romeo 4C.features.efficient") },
        { icon: "Shield", text: t("Alfa Romeo 4C.features.safety") },
        { icon: "Wind", text: t("Alfa Romeo 4C.features.air") },
        { icon: "Map", text: t("Alfa Romeo 4C.features.navigation") },
        { icon: "Speaker", text: t("Alfa Romeo 4C.features.music") }  
      ],
      images: [
        alfa_1,
        alfa_2,
        alfa_3
      ]
    }
  ];

  return (
    <section id="about_us" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">{t('about_us.title')}</h2>
        <div className="max-w-7xl mx-auto">
          <p className="text-gray-700 text-center mb-12 max-w-2xl mx-auto">
            {t('abaut_us.text')}
          </p>
          <VehicleCarousel vehicles={vehicles} />
        </div>
      </div>
    </section>
  );
};