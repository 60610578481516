// components/About/VehicleCarousel.jsx
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { VehicleCard } from './VehicleCard';

export const VehicleCarousel = ({ vehicles }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const moveCarousel = (newDirection) => {
    setDirection(newDirection);
    if (newDirection === 'left') {
      setActiveIndex((prev) => (prev === 0 ? vehicles.length - 1 : prev - 1));
    } else {
      setActiveIndex((prev) => (prev === vehicles.length - 1 ? 0 : prev + 1));
    }
  };

  // Reset direction after animation
  useEffect(() => {
    const timer = setTimeout(() => setDirection(null), 500);
    return () => clearTimeout(timer);
  }, [direction]);

  return (
    <div className="relative h-[600px] w-full overflow-hidden">
      {/* Navigation Buttons */}
      <button
        onClick={() => moveCarousel('left')}
        className="absolute left-4 top-1/2 -translate-y-1/2 z-20 bg-black/50 p-3 rounded-full text-white hover:bg-black/70 transition-colors"
      >
        <ChevronLeft size={24} />
      </button>
      
      <button
        onClick={() => moveCarousel('right')}
        className="absolute right-4 top-1/2 -translate-y-1/2 z-20 bg-black/50 p-3 rounded-full text-white hover:bg-black/70 transition-colors"
      >
        <ChevronRight size={24} />
      </button>

      {/* Carousel Container */}
      <div className="relative w-full h-full flex items-center justify-center">
        {vehicles.map((vehicle, index) => {
          // Calculate the position for each card
          let position = index - activeIndex;
          
          // Handle wrap-around
          if (position < -1) position += vehicles.length;
          if (position > 1) position -= vehicles.length;
          
          // Define classes based on position
          const cardClasses = `
            absolute transition-all duration-500 ease-in-out
            ${position === 0 ? 'z-10 scale-100 opacity-100' : 'scale-85 opacity-60'}
            ${position === -1 ? '-translate-x-[60%] -translate-z-24' : ''}
            ${position === 1 ? 'translate-x-[60%] -translate-z-24' : ''}
            ${position === 0 ? 'translate-x-0' : ''}
          `;

          return (
            <div
              key={vehicle.id}
              className={cardClasses}
              style={{
                transform: `
                  perspective(1000px)
                  rotateY(${position * 45}deg)
                  translateX(${position * 50}%)
                  scale(${position === 0 ? 1 : 0.85})
                `,
                transition: 'all 0.5s ease-in-out',
                zIndex: position === 0 ? 10 : 5
              }}
            >
              <VehicleCard vehicle={vehicle} isActive={position === 0} />
            </div>
          );
        })}
      </div>

      {/* Indicators */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2 z-20">
        {vehicles.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 
              ${activeIndex === index 
                ? 'bg-yellow-500 w-6' 
                : 'bg-gray-400 hover:bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};

