// components/About/VehicleCard.jsx
import React from 'react';
import * as LucideIcons from 'lucide-react';
import { ImageSlider } from '../../ui/ImageSlider';

export const VehicleCard = ({ vehicle, isActive }) => {
  return (
    <div className={`
      bg-white rounded-xl shadow-xl overflow-hidden
      w-[400px] transition-all duration-500
      ${isActive ? 'shadow-2xl' : 'shadow-lg'}
    `}>
      <ImageSlider images={vehicle.images} />
      <div className="p-6">
        <h3 className="text-2xl font-bold mb-2">{vehicle.name}</h3>
        <div className="inline-block bg-yellow-500 text-white px-3 py-1 rounded-full text-sm mb-4">
          {vehicle.capacity}
        </div>
        <div className="grid grid-cols-2 gap-4">
          {vehicle.features.map((feature, index) => {
            const Icon = LucideIcons[feature.icon];
            return (
              <div key={index} className="flex items-center gap-2">
                <Icon size={20} className="text-yellow-500" />
                <span className="text-sm">{feature.text}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

